
  .footer-section-columns {
    display: flex;
    flex-direction: column;
    min-width: 190px;
  }
  .footer-section-columns span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    /* cursor: pointer; */
  }

  .footer-section-columns a {
    text-decoration: none;
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
  }
  
  
  /* @media (max-width: 1000px) {
    .footer-section-columns {
      margin: 1rem 0rem;
    }
  } */
  
  .parent1 {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;    
    text-align: center;     
}

.rights{
  text-align: center;
  padding-bottom: 15px;
}